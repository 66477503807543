import React from "react";
// import { Link } from "gatsby";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
// import TwitterIcon from "@material-ui/icons/Twitter";

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: "50px 0",
    overflow: "hidden",
    backgroundColor: grey["900"],
    color: grey["200"],
    backgroundImage: `url(/bg-3.png)`,
    "& a": {
      color: grey["200"],
    },
  },
  title: {
    fontSize: 24,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },
  small: {
    fontSize: 14,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  content: {
    "& > *:not(:first-child)": {
      marginTop: theme.spacing(2),
      marginBottom: 0,
    },
  },
  contentRight: {
    textAlign: "right",
    alignContent: "space-between",
    display: "flex",
    flexWrap: "wrap",
    "& small": {
      width: "100%",
      display: "block",
    },
  },
  menu: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    paddingTop: 10,
    alignItems: "center",
    "& a": {
      display: "inline-block",
      marginLeft: 15,
      textTransform: "uppercase",
      fontSize: 12,
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  },
}));

function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={7} className={classes.content}>
            <Typography variant="h2" className={classes.title}>
              Washington State Ballpark Public Facilities District
            </Typography>
            <Typography className={classes.small}>
              Mailing address: PO Box 94445, Seattle, WA 98124
              <br />
              Street address: 110 Edgar Martinez Drive South, Seattle, WA 98134
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={5}
            className={`${classes.content} ${classes.contentRight}`}
          >
            {/* <nav className={classes.menu}>
              <Link to="/privacy">Privacy</Link>
              <Link to="/site-map">Site Map</Link>
              <a
                href="https://twitter.com"
                rel="noopener noreferrer"
                target="_blank"
                title="Follow us on Twitter"
              >
                <TwitterIcon />
              </a>
            </nav> */}
            <small>© 2021 All Rights Reserved</small>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}

export default Footer;
